.logo-container{
    
    .solid-logo{
        position: absolute;
        top: 20%;
        left: 50%;
        width: 20%;
        border-radius: 80px;
    }
}

@media screen and (max-width: 1200px){
    .logo-container{

        .solid-logo{
            border-radius: 10px;
            width: 60%;
        }
    }
}