.portfolio-page{
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title{
        margin-left: 100px;
        margin-top: 100px;
    }
    
    .images-container{
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box{
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);
        
        .portfolio-image{
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .content{
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px, 20px;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.5) 0,
                rgba(0, 0, 0, 0.5)
            );
            bottom: -70px;
            transition: all .4s ease;
            -webkit-transition: all .4s ease;
        }
        .title{
            margin-bottom: 0;
            margin-top: 10px;
            margin-left: 20px;
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
        }

        .btn{
            margin-top: 30px;
            margin-bottom: 10px;
            margin-left: 20px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 1px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
            transition: all .4s ease;
            -webkit-transition: all .4s ease;
        }
        .btn:hover{
            transition: all .4s ease;
            -webkit-transition: all .4s ease;
            transform: translateY(-3px);
            background: #ffd700;
        }
        &:after{
            content: "";
            background: linear-gradient(180deg, #ffd700, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all  0.3 cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
            transition: all .4s ease;
            -webkit-transition: all .4s ease;
        }
        &:hover:after{
            opacity: 0.65;
            transition: all .4s ease;
            -webkit-transition: all .4s ease;
        }
        &:hover .content{
            bottom: 0;
            background-color: transparent;
            transition: all .4s ease;
            -webkit-transition: all .4s ease;
        }
    }

}

@media screen and (max-width: 1200px){
    .container.portfolio-page{
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        h1.page-title{
            margin-left: 20px;
            margin-top: 20px;
        }

        .image-box{
            height: 200px;
            max-width: calc(50% - 10px);
        }

        .title, .content, .btn{
            visibility: hidden;
        }
    }
}

