.contact-form{
    width: 100%;
    margin-top: 20px;

    ul{
        padding: 0;
        margin: 0;

        li{
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }
    }
    li.half{
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child{
            margin-left: 0;
        }
    }

    input[type="text"],
    input[type="email"]{
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color: #FFF;
        padding: 0 20px;
        box-sizing: border-box;
    }

    textarea{
        width: 100%;
        border: 0;
        background: #115173;
        height: 50px;
        font-size: 16px;
        color: #FFF;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button{
        color: #FFD700;
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #FFD700;
        float: right;
        border-radius: 4px;

    
    }
    .flat-button:active{
        color: #115173;
        background: #FFD700;
        border: 1px solid #115173
    }
}